

















import Vue from 'vue';

export default Vue.extend({
  name: 'TrustFactorItem',
  props: {
    imagePath: String,
    title: String,
    subtitle: String,
    to: Object,
  },
  methods: {
    onItemClick() {
      this.$router.push(this.to);
    },
  },
});
